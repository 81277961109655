import { SetCurrentUserAccount } from 'components/ApplicationContext/useInitSetCurrentUserAccount'
import { useEffect } from 'react'
import {
  findAccountByAccountNumber,
  findFirstAccessibleAccount,
} from 'hooks/useUserProfile'
import { LOCAL_STORAGE_KEYS } from 'lib/config'
import { CurrentUserAccount } from 'lib/types/account'
import { UserProfile } from 'lib/types/user'

type InitCurrentUserAccount = {
  setCurrentUserAccount: SetCurrentUserAccount
  userProfile?: UserProfile | null
  currentUserAccount?: CurrentUserAccount | null
  currentUserAccountId?: string | null
}

/**
 * Initialises the current user account via three possible avenues in the following order:
 *
 * 1. Session storage object ({@link CurrentUserAccount} obj)
 *     - The existence of this means it has already been set, so additional initialisation isn't needed.
 *     - Set under {@link LOCAL_STORAGE_KEYS.CURRENT_USER_ACCOUNT } key.
 *     - Due to the nature of session storage, the value is wiped on browser session end
 * 2. Local storage (Account ID/Number)
 *     - Grabs the account from the users profile using this value.
 *     - Set under {@link LOCAL_STORAGE_KEYS.CURRENT_USER_ACCOUNT_ID } key.
 *     - The value is wiped on logout.
 * 3. First accessible account in the users profile
 *     - The default if no current user account info is stored
 *
 * For 2 and 3, the retrieved account will then be stored in session storage.
 */
const useInitCurrentUserAccount = ({
  setCurrentUserAccount,
  userProfile,
  currentUserAccount,
  currentUserAccountId,
}: InitCurrentUserAccount): void => {
  // Initialise the current account
  useEffect(() => {
    if (!userProfile?.accounts) {
      return
    }

    let updatedUserAccount = null

    // If there's a user account then confirm it's still valid and return if so
    if (
      currentUserAccount &&
      findAccountByAccountNumber(
        userProfile.accounts,
        currentUserAccount.accountNumber,
        true
      ) != null
    ) {
      return
    }

    // If there's a stored user account id then attempt to retrieve the account from the users profile
    if (currentUserAccountId) {
      updatedUserAccount = findAccountByAccountNumber(
        userProfile.accounts,
        currentUserAccountId,
        true
      )
    }

    // Set the current user account to either the found account by number or the first accessible account
    setCurrentUserAccount(
      updatedUserAccount || findFirstAccessibleAccount(userProfile.accounts)
    )
  }, [userProfile])
}

export default useInitCurrentUserAccount
