import React, { forwardRef, Ref } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { GridProps } from '@mui/material/Grid'
import { ListItemProps } from '@mui/material/ListItem'
import { sanitizeTrackId } from 'lib/gtm'
import useStyles from './trackBoxStyles'

type TrackBoxProps = BoxProps &
  GridProps &
  ListItemProps & {
    id: string
    // Allows usage of TrackBox without inflencing/breaking css styles of child components
    transparent?: boolean
    // Optionally the data-track-group attr can be set onto the same element as the data-track-id.
    // Useful when there is only one element to track inside of a track group. Can also assist in
    // cases where adding multiple layers of divs (i.e. Box) causes issues.
    groupId?: string
    children: any
  }

// This is a GTM helper Box (or Grid) component that wraps children with data-track-id
const TrackBox = (
  { id, transparent, groupId, children, ...rest }: TrackBoxProps,
  _: Ref<HTMLDivElement>
) => {
  const classes = useStyles({ transparent })

  return (
    <Box
      {...(groupId ? { 'data-track-group': groupId } : {})}
      data-track-id={sanitizeTrackId(id)}
      className={classes.root}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default forwardRef(TrackBox)
