/* eslint-disable no-process-env, no-magic-numbers */
import { Method } from 'axios'

// feature flag environments are imported with a type of string rather
// than boolean, this function is to convert those values to boolean
const stringToBool = (val?: string): boolean => val === 'true'

export const NODE_ENV = process.env.NODE_ENV || 'production'
export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const API_URL_V1 = process.env.NEXT_PUBLIC_API_URL_V1
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
export const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV

export const THEME = process.env.NEXT_PUBLIC_THEME || 'scentregroup'

export const MEMBERS_ONLY_TOPIC_ID =
  process.env.NEXT_PUBLIC_MEMBERS_ONLY_TOPIC_ID || ''

/** Auth0 */
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ''
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE
export const AUTH0_SPA_CLIENT_ID =
  process.env.NEXT_PUBLIC_AUTH0_SPA_CLIENT_ID || ''
export const AUTH0_METADATA_NS = process.env.NEXT_PUBLIC_AUTH0_METADATA_NS || ''
export const AUTH0_AUTH_MAX_AGE_SECONDS =
  process.env.NEXT_PUBLIC_AUTH0_AUTH_MAX_AGE_SECONDS || 7 * 24 * 60 * 60 // 7 days

/** Contentful */
export const SC_CONTENTFUL_SPACE_ID =
  process.env.NEXT_PUBLIC_SC_CONTENTFUL_SPACE_ID || ''
export const SC_CONTENTFUL_ENV_ALIAS =
  process.env.NEXT_PUBLIC_SC_CONTENTFUL_ENV_ALIAS || ''
export const SC_CONTENTFUL_DELIVERY_API_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_SC_CONTENTFUL_DELIVERY_API_ACCESS_TOKEN || ''

/** Mirakl */
export const MIRAKL_URL = process.env.NEXT_PUBLIC_MIRAKL_URL

/** Google Tag Manager */
export const GTM_ACCOUNT = 'GTM-TTWJZKX'
/** IMPORTANT - START - NEVER TO BE SET FOR PRODUCTION. REQUIRED FOR DEVELOPEMENT/STAGING */
export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH || ''
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW || ''
export const GTM_COOKIES = process.env.NEXT_PUBLIC_GTM_COOKIES || ''

export const gtmAuthParamStr = GTM_AUTH ? `&gtm_auth=${GTM_AUTH}` : ''
export const gtmPreviewParamStr = GTM_PREVIEW
  ? `&gtm_preview=${GTM_PREVIEW}`
  : ''
export const gtmCookiesWinStr = GTM_COOKIES
  ? `&gtm_cookies_win=${GTM_COOKIES}`
  : ''
/** IMPORTANT - END */

/** Tableau **/
export const TABLEAU_SALES_REPORT_URL =
  process.env.NEXT_PUBLIC_TABLEAU_SALES_REPORT_URL

/** MUI X **/
export const MUI_X_LICENSE_KEY = process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY

/** Feature Flags */
export const FEAT_GOOGLE_TAG_MANAGER =
  stringToBool(process.env.NEXT_PUBLIC_FEAT_GOOGLE_TAG_MANAGER) || false

export const FEAT_ADMIN_CREATE_RETAILER =
  stringToBool(process.env.NEXT_PUBLIC_FEAT_ADMIN_CREATE_RETAILER) || false

export const FEAT_CONSOLIDATED_ARTICLES =
  stringToBool(process.env.NEXT_PUBLIC_FEAT_CONSOLIDATED_ARTICLES) || false

export const FEAT_BETA_TAB =
  stringToBool(process.env.NEXT_PUBLIC_FEAT_BETA_TAB) || false

export const FEAT_DIGITAL_NOTICEBOARD_CURATED_ARTICLES =
  stringToBool(
    process.env.NEXT_PUBLIC_FEAT_DIGITAL_NOTICEBOARD_CURATED_ARTICLES
  ) || false

export const HTTP_STATUS = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INVALID_ENTRY: 422,
  SERVER_ERROR: 500,
}

// Target Users
export const BRAND_USER = 'BRAND_USER'
export const BUSINESS_USER = 'BUSINESS_USER'
export const NO_ROLE = 'NO_ROLE'
export const MARKETPLACE_USER = 'MARKETPLACE_USER'

export const GRID_RULES = {
  // In Units, 1 Unit = 8px
  CONTAINER_PADDING: 4,
}

export const HTTP_METHOD: { [key: string]: Method } = {
  POST: 'post',
  DELETE: 'delete',
}

export const IMAGE_TYPES = {
  JPEG: '.jpeg',
  JPG: '.jpg',
  PNG: '.png',
}
export const MAXIMUM_IMAGE_SIZE = 50

export const VIDEO_TYPES = {
  MP4: '.mp4',
  MOV: '.quicktime',
}

export const LOCAL_VIDEO_TYPES = {
  MP4: 'video/mp4',
  MOV: 'video/quicktime',
}

export const WESTFIELD_DIRECT_SQUARE_IMAGE_RATIO = 1.15
export const MAXIMUM_VIDEO_SIZE = 100

export const MINIMUM_HERO_IMAGE_DIMENSIONS = {
  width: 1200,
  height: 514,
}

export const MINIMUM_SQUARE_IMAGE_DIMENSIONS = {
  width: 1200,
  height: 1200,
}

export const MINIMUM_RETAILER_LOGO_SET_DIMENSIONS = {
  width: 600,
  height: 600,
}

export const SUPPORTING_IMAGE_DIMENSIONS = {
  width: 600,
  height: 600,
}

export const createAUImageSubheaderCopy = (imageSize: number) =>
  `This image will be the face of your campaign. Images across our channels are all square - you can use our ` +
  `cropping tool once you have uploaded. Images must be a minimum height and width of ${imageSize}px.`

export const HERO_IMAGE_TYPES: {
  [key: string]: 'wide' | 'standard' | 'square'
} = {
  WIDE: 'wide',
  STANDARD: 'standard',
  SQUARE: 'square',
}

export const RETAILER_LOGO_IMAGE_TYPES: Record<
  string,
  'coloured' | 'transparent' | 'tileBackground'
> = {
  COLOURED: 'coloured',
  TRANSPARENT: 'transparent',
  TILE_BACKGROUND: 'tileBackground',
}

export const SUPPORTING_IMAGE = 'SUPPORTING_IMAGE'

export const MY_BRAND = 'MY_BRAND'

export enum POST_TYPE {
  Offer = 'offer',
  News = 'news',
  Event = 'event',
}

export enum RadioImageCardSize {
  DEFAULT,
  SMALL,
}

/** Post Status */
export const POST_STATUS = {
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  PLEASE_REVIEW: 'Please review',
  SCHEDULED: 'Scheduled',
  EXPIRED: 'Expired',
  LIVE: 'Live',
}
export const UNTITLED_POST = 'Draft Post'

export const MAXIMUM_SUPPORTING_IMAGES = 9

export const PARTNER_SUPPORT_EMAIL = 'partnersupport@scentregroup.com'
export const BUSINESS_SOLUTIONS_EMAIL = 'businesssolutions@scentregroup.com'

export const FAQ_URL =
  'https://cam.scentregroup.io/m/5a6a1d8410209ea/original/Westfield-Marketing-Hub-FAQs_2023_WIP-4.pdf'

// TODO: Static account details for Tranche Release 1
//       https://scentregroup.atlassian.net/browse/BSP-345
//       Remove once generic contacts can be added to Scentral account teams
export const RETAIL_PARTNERSHIP_ACCOUNT = {
  id: 'RETAIL_PARTNERSHIP_ACCOUNT',
  email: 'retailpartnerships@scentregroup.com',
  role: 'Retail Partnerships Team',
  phone: '',
  mobilePhone: '',
  name: '',
}

export const DASH_CARD_MIN_HEIGHT = '366px'

export const LOCAL_STORAGE_KEYS = {
  POST_DETAIL: 'post_detail',
  ACCEPTED_TERMS: 'has_accepted',
  CAMPAIGN_STEP: 'current_step',
  POST_ID: 'post_Id',
  POST_TYPE: 'post_type',
  // Ideally the value should also have an underscore between video & upload.
  // But we can't change it now as it will break the existing users.
  HAS_SEEN_VIDEO_UPLOAD_WELCOME: 'has_seen_videoupload_welcome',
  HAS_SEEN_CONTACT_DIRECTORY_NOTIFICATION:
    'has_seen_contact_directory_notification',
  RETAILER_ID: 'retailer_id',
  // This is also inconsistent.
  DISABLE_NEXT_PAGEVIEW: 'disable-next-pageview',
  START_DATE: 'start-date',
  END_DATE: 'end-date',
  CONTACT_DETAIL: 'contact-detail',
  USER_PROFILE: 'user-profile',
  HAS_USER_LOADED: 'has-user-loaded',
  CURRENT_USER_ACCOUNT: 'current-user-account',
  CURRENT_USER_ACCOUNT_ID: 'current-user-account-id',
  HAS_SEEN_LOGIN_NOTIFICATION: 'has_seen_login_notification',
}

export const HERO_IMAGE_TITLE = 'Hero image'
export const HERO_IMAGE_DESCRIPTION =
  'This image will be the face of your post and appear in several sizes. We recommend uploading a single jpg or png image at 2400 x 1028px (or a minimum of 1200 x 514px).'

export const SOCIAL_CONTENT_MEDIA_TITLE = 'Social media'
export const SOCIAL_CONTENT_MEDIA_DESCRIPTION =
  'Upload any supporting media for consideration on social channels here. Please submit story assets in 1080 x 1920px JPG/PNG and mp4 or mov for videos. Assets must be in high-resolution, lifestyle imagery to be considered for social. You can upload a maximum of 9 files. Please upload a variety of story assets if you have available.'

export const SOCIAL_CONTENT_COPY_TITLE = 'Social copy'
export const SOCIAL_CONTENT_COPY_HELPER_TEXT =
  'Include your suggested copy, any embargo dates or info we need to know, and required hashtags and @mentions here'

export const SWR_CONFIG = {
  revalidateOnFocus: false,
  refreshWhenHidden: false,
}

export const SOCIAL_COPY_ERROR = {
  NO_IMAGES_UPLOADED: "You haven't uploaded any social images",
}

export const CTA_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
}

export const CTA_LABELS = {
  SUBMIT_CONTENT: 'submit content',
}

export const PARTNER_URL_PREFIX = 'partner.'
export const SCENTREGROUP_URL_DOMAIN = 'scentregroup.'
export const URL_REDIRECT_WHITELIST = [
  'cloudinary.com',
  'ctfassets.net',
  'scentregroup.com',
  'scentregroup.io',
]

export const initialImageState = {
  heroImage: () => ({
    id: null,
    master: {
      id: null,
      url: null,
      isSquare: false,
    },
    square: {
      id: null,
      url: null,
    },
    standard: {
      id: null,
      url: null,
    },
  }),
  supportingImage: () => ({
    id: null,
    description: null,
    images: [],
  }),
}

// Posts

export const DEFAULT_POSTS_PER_PAGE = 50
export const BASE_POSTS_URI = '/marketing-hub/campaigns'

// My Brand

export const WESTFIELD_BASE_URL_AUSTRALIA = 'https://www.westfield.com.au'

// Campaign Alert

export const LOCKED_TITLE = 'This post is locked and cannot be edited'
export const LIVE_TITLE = 'This post is currently live and cannot be edited'
export const EXPIRED_TITLE = 'This post is already expired and cannot be edited'
export const LOCKED_BODY =
  'This post applies to stores that you do not have permission to manage'
export const LIVE_BODY =
  'Unpublish it from live to make changes or duplicate it as a template for a new post'
export const EXPIRED_BODY = 'Duplicate to use as a template for a new post'

// Dashboard

export const SCENTREGROUP_BASE_URL =
  process.env.NEXT_PUBLIC_SCENTREGROUP_BASE_URL ||
  'https://www.scentregroup.com'
