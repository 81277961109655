import React, { ReactNode } from 'react'
import Grid from '@mui/material/Grid'
import useStyles from './styles'

const BaseLayout = ({
  route,
  children,
}: {
  route?: string
  children: ReactNode
}) => {
  const classes = useStyles({ route: route || '' })
  return (
    <Grid container classes={{ root: classes.root }}>
      <Grid item className={classes.containerDiv}>
        {children}
      </Grid>
    </Grid>
  )
}

export default BaseLayout
