import { POST_TYPE, UNTITLED_POST } from 'lib/config'
import { DateTimeFormat } from 'lib/types/dateUtils'
import {
  Store,
  PostData,
  RetailerProfile,
  SupportingImageSet,
} from 'lib/types/form'
import {
  VIEW_OPTION,
  getAllStores,
  removeRetailerFromTitle,
  removeDatestampFromTitle,
} from 'lib/utils'
import { formatDate } from '../dateUtils'
import { HeroImageSet } from 'lib/types/images'

export const countryCodes: { [key: string]: string } = {
  Australia: 'AU',
  'New Zealand': 'NZ',
}

export const shapeCampaignPostFormReset = (
  postType: POST_TYPE,
  postData: PostData,
  stores: Store[]
) => ({
  allStores: getAllStores(postData, stores),
  formType: postType,
  viewOption: postData?.viewOption || VIEW_OPTION.EVERYONE,
  stores: (postData?.stores && Object.values(postData?.stores)) || [],
  showAt: postData?.showAt,
  hideAt: postData?.hideAt,
  physicalAddress: postData?.physicalAddress,
  ...(postType === POST_TYPE.Event && {
    occurrences: postData?.occurrences?.map(item => ({
      ...item,
      backendId: item?.id,
    })) || [
      {
        startAt: null,
        endAt: null,
      },
    ],
  }),
  ...(postType === POST_TYPE.Offer && {
    startAt: postData?.startAt,
    endAt: postData?.endAt,
  }),
})

// TODO: consolidate this function since there's already a similar function for generating `backendTitle`
// TODO: cleanup this function because its stripping the retailer then re-adding it again,
// we could have the title stripped of the retailer after fetching from contentful
// then only adding it when submitting to contentful. This will reduce the redundancy of this function being called
// TODO: merge removeRetailerFromTitle and removeDatestampFromTitle to a single function
export const generateBackendTitle = ({
  retailer,
  stores,
  postTitle = '',
}: {
  retailer: RetailerProfile
  stores: string[]
  postTitle?: string
}) => {
  const countryCode = retailer.country === 'Australia' ? 'AU' : 'NZ'

  const dateTime = formatDate(new Date(), DateTimeFormat.TITLE)
  const title =
    removeRetailerFromTitle(
      retailer.title,
      removeDatestampFromTitle(postTitle)
    ) || UNTITLED_POST

  if (!stores || !stores.length) {
    return `${retailer.title}: ${title} - no stores - ${dateTime}`
  } else if (stores.length > 3) {
    return `${retailer.title}: ${title} - ${countryCode} - ${dateTime}`
  } else {
    const selectedCenters = stores.join(' - ')
    return `${retailer.title}: ${title} - ${selectedCenters} - ${dateTime}`
  }
}

export const generatePostTitle = ({
  retailer,
  postTitle = '',
}: {
  retailer: RetailerProfile
  postTitle?: string
}) => {
  const dateTime = formatDate(new Date(), DateTimeFormat.TITLE)
  const titleWithSuffix =
    removeRetailerFromTitle(
      retailer.title,
      removeDatestampFromTitle(postTitle)
    ) || `${UNTITLED_POST} - ${dateTime}`

  return `${retailer.title}: ${titleWithSuffix}`
}

// Generates title and backendTitle for campaign posts
export const getGroupNames = ({
  retailer,
  stores,
  postTitle = '',
}: {
  retailer: RetailerProfile
  stores: string[]
  postTitle?: string
}) => ({
  title: generatePostTitle({
    retailer,
    postTitle,
  }),
  backendTitle: generateBackendTitle({
    retailer,
    stores,
    postTitle,
  }),
})

export const sanitizeTerms = (terms?: string) =>
  terms?.replace('__Terms and conditions__ \n ', '') || ''

export const transformHeroImage = (heroImageSet: HeroImageSet) => {
  const keys = [
    'master',
    'square',
    'standard',
  ] as unknown as (keyof HeroImageSet)[]
  return {
    ...heroImageSet,
    ...keys.reduce((acc, current) => {
      if ((heroImageSet[current] as HeroImageSet)?.preview) {
        return {
          ...acc,
          [current]: {
            id: (heroImageSet[current] as HeroImageSet)?.id,
            url: (heroImageSet[current] as HeroImageSet)?.preview,
          },
        }
      }
      return { ...acc, [current]: {} }
    }, {}),
  }
}

export const transformSupportingImages = (
  supportingImages: SupportingImageSet
) => ({
  id: supportingImages?.id,
  description: supportingImages?.description,
  images: supportingImages?.images?.map((image: any) => ({
    id: image?.id,
    name: image?.name,
    url: image?.url,
    preview: image?.preview,
    contentType: image?.contentType,
    assetLocation: image?.assetLocation,
    extension: image?.extension,
    fileSize: image?.fileSize,
  })),
})

export const mapDataToFormCampaignState = (
  postType: POST_TYPE,
  postData: PostData,
  stores: Store[]
) => {
  const { topics, ...remainingPostData } = postData
  return {
    ...remainingPostData,
    ...shapeCampaignPostFormReset(postType, postData, stores),
    termsAndConditions: sanitizeTerms(postData?.termsAndConditions),
    topic: postData?.topics,
    heroImageSet:
      postData?.heroImageSet && transformHeroImage(postData?.heroImageSet),
    supportingImageSet:
      postData?.supportingImageSet &&
      transformSupportingImages(postData?.supportingImageSet),
  }
}
