import { findAccountByAccountNumber } from 'hooks/useUserProfile'
import { fetcher } from 'lib/api'
import { currentUserAccountAtom } from 'lib/atoms/account'
import { API_URL, LOCAL_STORAGE_KEYS } from 'lib/config'
import { AccountPermission } from 'lib/permission'
import { CurrentUserAccount } from 'lib/types/account'
import { UserProfile, UserProfileAccountTreeItem } from 'lib/types/user'
import { useSetRecoilState } from 'recoil'

export type SetCurrentUserAccount = (
  accountOrNumber?: string | UserProfileAccountTreeItem | null
) => Promise<boolean>

const useInitSetCurrentUserAccount = (
  currentUserAccount?: CurrentUserAccount | null,
  userProfile?: UserProfile | null
) => {
  const setCurrentUserAccountAtom = useSetRecoilState(currentUserAccountAtom)

  return (async (accountOrNumber?) => {
    let account: UserProfileAccountTreeItem | null | undefined = null

    if (typeof accountOrNumber === 'string') {
      if (accountOrNumber !== currentUserAccount?.accountNumber) {
        account = findAccountByAccountNumber(
          userProfile?.accounts || [],
          accountOrNumber,
          true
        )
      }
    } else if (
      accountOrNumber?.accountNumber != currentUserAccount?.accountNumber
    ) {
      account = accountOrNumber
    }

    if (!account) {
      return
    }

    // Opted to not use SWR in this case as we need to retrieve permissions on demand and SWR has very limited support
    // for querying 'lazily', and this support doesn't even cache anyway.
    const permissionsResponse = await fetcher<AccountPermission[]>(
      `${API_URL}/me/accounts/${account.accountNumber}/permissions`
    )
    setCurrentUserAccountAtom({
      accountNumber: account.accountNumber,
      accountName: account.accountName,
      permissions: permissionsResponse.data,
    })
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.CURRENT_USER_ACCOUNT_ID,
      account.accountNumber
    )

    return true
  }) as SetCurrentUserAccount
}

export default useInitSetCurrentUserAccount
