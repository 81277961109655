type TraversalItem<TraversalItemChild> = object & {
  children: TraversalItemChild[]
}

/**
 * Descriptions shamelessly taken from {@link https://en.wikipedia.org/wiki/Tree_traversal}
 */
enum SearchMethod {
  /**
   * Depth first 'pre-order' method (top down, left to right)
   *
   * @example
   *         F
   *        / \
   *       B   G
   *      / \   \
   *     A   D   I
   *        / \   \
   *       C   E   H
   *
   * // Search order is F, B, A, D, C, E, G, I, H
   */
  DepthFirstSearchPreOrder = 'DepthFirstSearchPreOrder',
}

/**
 * Finds the first matching item in the hierarchical list via traversal using the specified search method
 *
 * @param items List of hierarchical items
 * @param callback The callback executed on each item during traversal. If true is returned, the traversal execution is
 *                 stopped and the item pertaining to the true result is returned.
 * @param [searchMethod] The traversal search method. Defaults to DepthFirstSearchPreOrder
 */
export const find = <Item extends TraversalItem<Item>>(
  items: Item[],
  callback: (item: Item) => boolean,
  searchMethod = SearchMethod.DepthFirstSearchPreOrder
): Item | null => {
  let found = null

  if (!searchMethod) {
    return null
  }

  for (const item of items) {
    if (callback(item)) {
      found = item
    } else {
      found = find(item.children, callback)
    }

    if (found) {
      break
    }
  }

  return found
}

/**
 * Reduces the specified hierarchical list into a single result via traversal using the specified search method
 *
 * @param items List of hierarchical items
 * @param reduceFn The reduce function
 * @param initial The initial result
 * @param [searchMethod] The traversal search method. Defaults to DepthFirstSearchPreOrder
 */
export const reduce = <Item extends TraversalItem<Item>, Result>(
  items: Item[],
  reduceFn: (result: Result, item: Item) => Result,
  initial: Result,
  searchMethod = SearchMethod.DepthFirstSearchPreOrder
) => {
  let result = initial

  if (!searchMethod) {
    return initial
  }

  for (const item of items) {
    result = reduce(item.children, reduceFn, reduceFn(result, item))
  }

  return result
}
