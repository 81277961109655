import { makeStyles } from '@mui/styles'

type StyleProps = {
  transparent?: boolean
}

export default makeStyles(() => ({
  root: ({ transparent }: StyleProps) => ({
    ...(transparent && { display: 'contents' }),
  }),
}))
