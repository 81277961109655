import { CircularProgress, FilterOptionsState, Grow } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Paper, { PaperProps } from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { matchSorter } from 'match-sorter'
import React, { forwardRef, useMemo, useState } from 'react'
import styles from './styles'
import {
  HierarchicalAutocompleteValue,
  HierarchicalOption,
  Option,
} from './types'
import {
  maintainOptionHierarchy,
  mapHierarchicalOptionsById,
  mapToOption,
  mapToOptions,
} from './util'

export type { HierarchicalOption } from './types'

type Props = {
  options: HierarchicalOption[]
  value?: HierarchicalAutocompleteValue | null
  onChange: (value: HierarchicalAutocompleteValue | null) => void
  placeholder?: string
  loading?: boolean
  'data-testid'?: string
}

const PaperComponent = (paperProps: PaperProps, ref: any) => {
  return (
    <Grow in style={{ transformOrigin: '0 0 0' }}>
      <Paper elevation={2} {...paperProps} ref={ref} />
    </Grow>
  )
}

const PaperComponentForward = forwardRef(PaperComponent)

const HierarchicalAutocomplete = ({
  onChange,
  options: hierarchicalOptions,
  value,
  loading,
  placeholder,
  'data-testid': dataTestId,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [isInputDirty, setInputDirty] = useState(false)
  const valueMap = useMemo(
    () => mapHierarchicalOptionsById(hierarchicalOptions),
    [hierarchicalOptions]
  )
  const options = useMemo(
    () => mapToOptions(hierarchicalOptions),
    [hierarchicalOptions]
  )

  const customFilterOptions = (
    options: Option[],
    state: FilterOptionsState<Option>
  ) => {
    const filteredOptions = matchSorter(
      options.filter(option => !option.disabled),
      state.inputValue,
      { keys: ['label'] }
    )

    return maintainOptionHierarchy(hierarchicalOptions, filteredOptions)
  }

  // As recommended by the MUI team to cache the value reference
  // https://mui.com/material-ui/react-autocomplete/#controlled-states
  const cachedValue = useMemo(
    () => (value ? mapToOption(value) : null),
    [value?.id]
  )

  return (
    <Autocomplete
      {...(dataTestId ? { 'data-testid': dataTestId } : {})}
      autoHighlight
      sx={styles.autocomplete(loading)}
      options={options}
      disableClearable={value != null}
      loading={loading}
      value={cachedValue}
      onChange={(event, updatedValue) => {
        onChange(updatedValue && valueMap[updatedValue.id])
      }}
      inputValue={inputValue}
      onClose={() => setInputDirty(false)}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          setInputDirty(true)
        }

        setInputValue(value)
      }}
      filterOptions={customFilterOptions}
      PaperComponent={PaperComponentForward}
      componentsProps={{
        popper: { style: { width: 'fit-content' }, placement: 'bottom-start' },
      }}
      getOptionLabel={option => option.label}
      getOptionDisabled={option => option.disabled}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.label, inputValue, { insideWords: true })
        const parts = parse(option.label, matches)

        return (
          <Box
            {...props}
            key={option.id}
            component="li"
            sx={styles.option(option)}
            minWidth={290}
          >
            <Box sx={styles.optionInner(option)}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={styles.highlightedPart(
                    part.highlight && !option.disabled && isInputDirty
                  )}
                >
                  {part.text}
                </span>
              ))}
            </Box>
          </Box>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  )
}

export default HierarchicalAutocomplete
