import { User } from '@auth0/auth0-react'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { assign, isEqual, keyBy, mapValues, sortBy } from 'lodash'
import Color from 'tinycolor2'
import { centreLevelOptions } from 'components/Forms/Event/lib'
import {
  IMAGE_TYPES,
  initialImageState,
  LOCAL_VIDEO_TYPES,
  MINIMUM_SQUARE_IMAGE_DIMENSIONS,
  POST_STATUS,
  SCENTREGROUP_BASE_URL,
  UNTITLED_POST,
  VIDEO_TYPES,
  WESTFIELD_DIRECT_SQUARE_IMAGE_RATIO,
} from 'lib/config'
import { hasUserPermission, Permission } from 'lib/permission'
import { CampaignCard, Card, ColourScheme } from 'lib/types/card'
import { Status } from 'lib/types/common'
import {
  CampaignPostData,
  Centre,
  Cta,
  MyBrandPostData,
  MyStorePostData,
  PostData,
  PostProps,
  Retailer,
  SelectProps,
  SocialPostData,
  Store,
  SupportingImageSet,
} from 'lib/types/form'
import {
  AssetLocation,
  HeroImageSet,
  ImageType,
  RetailerLogoSet,
} from 'lib/types/images'
import { ImageDimensions } from 'lib/types/utils'
import { formatDate, getDateAfterNDays } from './dateUtils'
import { countryCodes } from './forms/shared'
import { IOptionWithImage, IOptionWithImageAndTooltip } from './types/campaign'
import { DateTimeFormat } from './types/dateUtils'

export function toOptions(values: string[]) {
  return values.map(value => ({ value, label: value }))
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

//is Admin user
export const isWMHAdminUser = (user?: User): boolean => {
  if (!user) {
    return false
  }

  const hasWMHAdminRole = hasUserPermission(Permission.SystemAdmin, user)
  const userEmail = user?.email || ''
  const lowerCased = userEmail?.toLowerCase()
  const isScentreGroupEmployee = /@scentregroup.com/.test(lowerCased)
  return hasWMHAdminRole && isScentreGroupEmployee
}

export const isPathMatchingRoute = (
  path: string,
  route: { url: string; exact: boolean }
) => (route.exact ? route.url === path : path.startsWith(route.url))

export const paymentMethodOptions = [
  { value: 'Afterpay', label: 'Afterpay' },
  { value: 'Alipay', label: 'Alipay' },
  { value: 'American Express', label: 'American Express' },
  { value: 'Mastercard', label: 'Mastercard' },
  { value: 'Union Pay', label: 'Union Pay' },
  { value: 'Visa', label: 'Visa' },
  { value: 'Westfield Giftcard', label: 'Westfield Giftcard' },
  { value: 'Not Applicable', label: 'Not Applicable' },
]

export const countryOptions = [
  { label: 'Australia', value: 'Australia' },
  { label: 'New Zealand', value: 'New Zealand' },
]

export const roleOptions = [
  {
    label: 'Marketing / Comms / Content Assistant',
    value: 'Marketing / Comms / Content Assistant',
  },
  {
    label: 'Marketing / Comms / Content Co-ordinator or Executive',
    value: 'Marketing / Comms / Content Co-ordinator or Executive',
  },
  {
    label: 'Marketing / Comms / Content Manager',
    value: 'Marketing / Comms / Content Manager',
  },
  { label: 'Store Manager', value: 'Store Manager' },
  { label: 'Area / Regional Manager', value: 'Area / Regional Manager' },
  { label: 'Retail Operations', value: 'Retail Operations' },
  { label: 'E-commerce Manager', value: 'E-commerce Manager' },
  {
    label: 'VM / Merchandising / Graphic Designer',
    value: 'VM / Merchandising / Graphic Designer',
  },
  {
    label: 'Owner / Director / CEO / General Manager',
    value: 'Owner / Director / CEO / General Manager',
  },
  { label: 'Marketing Analyst', value: 'Marketing Analyst' },
  { label: 'Other', value: 'Other' },
]

export enum VIEW_OPTION {
  MEMBERS_ONLY = 'Members only',
  EVERYONE = 'Everyone',
}

export const postTypeOptionsWithImage: IOptionWithImage[] = [
  {
    title: 'Offer',
    imageSrc: 'offer-icon.png',
    description:
      'Deals or promotions that provide customers with additional value when they shop with you. e.g. 40% off storewide, gift with purchase.',
    value: 'offer',
  },
  {
    title: 'Event',
    imageSrc: 'event-icon.png',
    description:
      'An in-store event that customers can attend. This could be free or have an associated cost. e.g. book signing, live music.',
    value: 'event',
  },
  {
    title: 'News',
    imageSrc: 'news-icon.png',
    description:
      'Timely, news-worthy content. e.g. new collection, store opening, evergreen content and brand news.',
    value: 'news',
  },
]

export const audienceTypeOptions: IOptionWithImageAndTooltip[] = [
  {
    title: 'All Westfield customers',
    value: VIEW_OPTION.EVERYONE,
    imageSrc: 'audience_all.png',
    description:
      'Promote offers, news and events to our extensive Westfield audience.',
  },
  {
    title: 'Westfield member exclusive',
    imageSrc: 'westfield-membership-logo.svg',
    description:
      'Provide exclusive offers and experiences for our highly engaged members',
    value: VIEW_OPTION.MEMBERS_ONLY,
    tooltipText:
      'This content will only be visible to members on app and eDM and will not appear on web',
  },
]

export const serviceOptionValues = [
  'Alterations',
  'Catering',
  'Childminding',
  'Click and Collect',
  'Gift Registry',
  'Lay-by',
  'Personal Stylist',
  'Store Gift Card',
]

export const DEFAULT_CATEGORY_VALUE = 'Please select'
export const categoryOptionValues = [
  DEFAULT_CATEGORY_VALUE,
  ...sortBy([
    'View menu',
    'Book appointment',
    'Order delivery',
    'Order pickup',
    'View catalogue',
    'Book a table',
    'Book now',
  ]),
]
export const eventCategoryOptionValues = [
  DEFAULT_CATEGORY_VALUE,
  ...sortBy(['Book a table', 'Book appointment', 'Book now', 'Buy a ticket']),
]

export const postDefaultDateAndTime = {
  startDateAndTime: new Date(new Date().setHours(9, 30)),
  todayEndDateAndTime: new Date(new Date().setHours(18, 0)),
  endDateAndTime: new Date(getDateAfterNDays(14).setHours(18, 0)),
}

export const postTimeOnDateSelection = {
  startTime: '9:30 am',
  endTime: '6:00 pm',
}

export const isImageFile = (contentType: string): boolean => {
  const imageTypes = [IMAGE_TYPES.JPEG, IMAGE_TYPES.PNG, IMAGE_TYPES.JPG]
  const extension = contentType.split('/')[1]
  return imageTypes.includes(`.${extension}`)
}

export const isVideoFile = (contentType: string): boolean => {
  const videoTypes = [
    VIDEO_TYPES.MP4,
    VIDEO_TYPES.MOV,
    LOCAL_VIDEO_TYPES.MP4,
    LOCAL_VIDEO_TYPES.MOV,
  ]
  return videoTypes.includes(contentType)
}

export const hasUploadedImages = (
  heroImageSet: HeroImageSet,
  supportingImageSet: SupportingImageSet
): boolean =>
  Boolean(
    heroImageSet?.master?.id ||
      heroImageSet?.square?.id ||
      heroImageSet?.standard?.id ||
      (supportingImageSet && supportingImageSet?.images?.length > 0)
  )
const array2object = (arr?: string[]) => {
  if (Array.isArray(arr)) {
    return arr.reduce((obj, cur) => ({ ...obj, [cur]: cur }), {})
  }
  return arr
}

const removeEmptyCTAs = (response: PostData) =>
  response.callsToAction
    ? {
        ...response,
        callsToAction: response.callsToAction.filter(
          (cta: Cta) =>
            cta.category &&
            cta.category !== DEFAULT_CATEGORY_VALUE &&
            cta.address.length > 0
        ),
      }
    : response

export function prepareResponse(
  inputData: PostData,
  data: PostData | MyStorePostData | MyBrandPostData,
  {
    heroImageSet,
    retailerLogoSet,
  }: {
    heroImageSet?: HeroImageSet
    retailerLogoSet?: RetailerLogoSet
  },
  retailer?: Retailer
) {
  const response = assign(inputData, data)
  const hasTitle = Boolean(data.title)
  const hasRetailers = Boolean(retailer?.title)
  let optionalResponseParameters = {}

  if (hasTitle && hasRetailers) {
    optionalResponseParameters = {
      backendTitle: data.title,
    }
  }

  return {
    ...removeEmptyCTAs(response),
    ...optionalResponseParameters,
    // Api accepts object based instead of array so we'll shape it a bit here
    paymentMethods: array2object(data?.paymentMethods) ?? {},
    services: array2object(data?.services) ?? {},
    heroImageSet: {
      ...(heroImageSet &&
        mapImageSet(heroImageSet, inputData?.heroImageSet?.id)),
    },
    retailerLogoSet: retailerLogoSet
      ? mapImageSet(retailerLogoSet, inputData?.retailerLogoSet?.id)
      : undefined,
  }
}

export const parseDateString = (dateStr?: string) =>
  dateStr ? new Date(dateStr) : null

// Lifted from types/autoSlug
export const autoCalcSlug = (retailer: string, val: string) =>
  [retailer, val]
    .join(' ')
    .toLowerCase()
    .trim()
    .replace(/ +/g, '_')
    .replace(/\W+/g, '')
    .replace(/_+/g, '-')

export const getAbsoluteUrl = (link: string) => {
  if (
    link.indexOf('http://') === 0 ||
    link.indexOf('https://') === 0 ||
    link.indexOf('blob:http://') === 0 ||
    link.indexOf('blob:https://') === 0
  ) {
    // http exists in the link - Link is absolute url
    return link
  }
  return `https://${link}`
}

export const autoCalcRetailerPrefix = (
  val = '',
  whiteSpaceBetween = false
): string => {
  const defaultSuffix = whiteSpaceBetween ? ' : ' : ': '
  if (val.endsWith('.') || val.endsWith('!')) {
    return val
  }
  return `${val}${defaultSuffix}`
}

export const autoCalcPostTitle = (retailer: string, title: string): string => {
  const titlePrefix = autoCalcRetailerPrefix(retailer)
  const currentDate = formatDate(new Date(), DateTimeFormat.TITLE)
  return `${titlePrefix}${title || UNTITLED_POST} - ${currentDate}`
}

// Removes retailer prefix irregardless of with space between : or not
export const removeRetailerFromTitle = (
  retailer: string,
  title: string,
  { showUntitled = false } = {}
): string => {
  // Caters both ' : ' and ': '
  const toRemoveTitle = [
    autoCalcRetailerPrefix(retailer),
    autoCalcRetailerPrefix(retailer, true),
  ]
  const regexp = new RegExp(`\\b${toRemoveTitle.join('|')}\\b`, 'gi')
  const newTitle = title?.replace(regexp, '')

  if (newTitle === UNTITLED_POST && !showUntitled) {
    return ''
  }
  return newTitle
}

// This function removes all instances of datestamp (with ' - DD-MM-YYYY') on the title
export const removeDatestampFromTitle = (title: string): string =>
  title
    .replace(/ - (0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-[0-9]{4}/g, '')
    .trim()

export function action<T extends unknown>(type: string, payload: T) {
  return {
    type,
    payload,
  }
}

// Since at the FE we are using array for validation purposes
// And the api needs an object based {id: 'id'}.
// We need to do some data shape manipulation to conform to that upon sending the api request
export const shapeStoresToObject = (stores: string[]) =>
  stores.reduce((obj, cur) => ({ ...obj, [cur]: cur }), {})

export const prependTNC = (val: string) =>
  val && `__Terms and conditions__ \n ${val}`

export const getSocialRegex = (type: string) =>
  new RegExp(`^(?:(http|https)://(www.)?${type}.com/([a-zA-Z0-9_.-]+)(/)?)?$`)

function mapImage(image: ImageType | string) {
  if (typeof image === 'string') {
    return image
  } else if (image?.id) {
    return {
      id: image?.id,
      preview: image?.url,
      published: image?.published || false,
      assetLocation: image?.assetLocation || AssetLocation.CONTENTFUL,
    }
  } else {
    return {}
  }
}

const mapImageSet = (
  imageSet: ImageType[] | HeroImageSet | SupportingImageSet | RetailerLogoSet,
  imageSetId?: string | null
) => ({
  ...mapValues(imageSet, mapImage),
  id: imageSetId || null,
  masterSupportingColour: (imageSet as HeroImageSet).masterSupportingColour,
})

export const shapeHeroImageSet = (
  data: PostData | CampaignPostData,
  retailer: Retailer
): HeroImageSet => {
  if (data?.heroImageSet?.master?.url) {
    return {
      ...(mapImageSet(
        data.heroImageSet,
        data.heroImageSet?.id
      ) as HeroImageSet),

      backendTitle: `${autoCalcPostTitle(
        retailer?.title,
        removeRetailerFromTitle(
          retailer?.title,
          removeDatestampFromTitle(data.title)
        )
      )}_hero_image_set`,
    }
  } else {
    return initialImageState.heroImage()
  }
}

export const getAllStores = (postData: PostData, stores: Store[]) =>
  Object.keys(postData?.stores || []).length === stores.length
    ? ['allStores']
    : []

export const formatDashboardCard = (
  item: any & { fields: Record<string, any> }
): Card => {
  if (item.sys?.contentType?.sys?.id === 'page') {
    return formatCorporateDashboardCard(item)
  }

  if (item.__typename === 'Article' || item.__typename === 'PreviewCard') {
    return formatWmhGraphQLDashboardCard(item)
  }

  return formatWmhDashboardCard(item)
}

export const formatCorporateDashboardCard = (item: {
  sys: { id: string }
  fields: {
    tileImage: Record<string, any>
    title: string
    tileSummary: string
    path: string
    date: string
  }
}) => {
  const fields = item.fields
  const imageAsset = fields.tileImage?.fields?.file

  const image = {
    src: imageAsset?.url,
    height: imageAsset?.details?.image?.height,
    width: imageAsset?.details?.image?.width,
  }

  return {
    id: item.sys.id,
    ...(image && { image }),
    kicker: dayjs(fields.date).format('DD MMMM YYYY'),
    optimiseImage: true,
    title: fields.title,
    description: fields.tileSummary,
    cta: {
      url: new URL(fields.path, SCENTREGROUP_BASE_URL).toString(),
    },
  }
}

export const formatWmhGraphQLDashboardCard = (item: any) => {
  const thumbnail = item?.thumbnailImageSet?.masterImage
  const hero = item?.heroImageSet?.masterImage
  const image = {
    src: thumbnail?.url || hero?.url,
    height: thumbnail?.height || hero?.height,
    width: thumbnail?.width || hero?.width,
  }

  return {
    id: item.sys.id,
    ...(image && { image }),
    kicker: item.cardKicker || item.kicker,
    title: item.cardTitle || item.title,
    description: item.cardBody || item.body,
    cta: {
      // Consolidated Article CTA || Preview Card CTA || Article
      url:
        item.cardCta?.address ||
        item.callToAction?.address ||
        `/marketing-hub/articles/${item.sys.id}`,
    },
    buttonText: item.cardCta?.label || item.callToAction?.label || '',
    colour: item.cardColour || item.colour,
  }
}

export const formatWmhDashboardCard = (item: {
  sys: { id: string }
  fields: {
    // Article
    thumbnailImageSet: Record<string, any>
    heroImageSet: Record<string, any>
    cardCta: Record<string, any>
    cardKicker: string
    cardTitle: string
    cardBody: string
    title: string
    cardColour: string
  }
}) => {
  const fields = item.fields
  const imageAsset = (fields.thumbnailImageSet || fields.heroImageSet)?.fields
    ?.masterImage?.fields?.file
  const cardCta = fields.cardCta?.fields

  const image = {
    src: imageAsset?.url,
    height: imageAsset?.details?.image?.height,
    width: imageAsset?.details?.image?.width,
  }

  return {
    id: item.sys.id,
    ...(image && { image }),
    kicker: fields.cardKicker,
    title: fields.cardTitle || fields.title,
    description: fields.cardBody,
    cta: {
      url: cardCta?.address || `/marketing-hub/articles/${item.sys.id}`,
    },
    buttonText: cardCta?.label || '',
    colour: fields.cardColour,
  }
}

export const shapeSupportingImageSet = ({
  retailer,
  data,
}: {
  retailer: Retailer
  data: PostData | CampaignPostData | SocialPostData
}): SupportingImageSet | undefined => {
  const backendTitle = `${autoCalcPostTitle(
    retailer?.title,
    removeRetailerFromTitle(retailer?.title, data.title)
  )}_supporting_image_set`

  const description = data?.supportingImageSet?.description || ''

  const { supportingImageSet } = data

  if (supportingImageSet && supportingImageSet.images?.length > 0) {
    return {
      ...supportingImageSet,
      backendTitle,
      description,
      images: [
        ...(supportingImageSet?.images || []).map(v => ({
          id: v.id,
          name: v.name,
          url: v.url,
          preview: v.preview,
          published: false,
          contentType: v.contentType,
          assetLocation: v.assetLocation,
          extension: v.extension,
          fileSize: v.fileSize,
        })),
      ],
    }
  } else {
    return undefined
  }
}

export const getImageDimensions = async (
  file: File | string
): Promise<ImageDimensions> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve({ height: img.height, width: img.width })
    img.onerror = reject
    img.src = typeof file === 'string' ? file : URL.createObjectURL(file)
  })

export const deepComparePost = (props: PostProps, nextProps: PostProps) => {
  if (props.isSubmitting !== nextProps.isSubmitting) {
    return false
  }

  if (!isEqual(props.retailer, nextProps.retailer)) {
    return false
  }

  if (!isEqual(props.stores, nextProps.stores)) {
    return false
  }

  if (isEqual(props.postData, nextProps.postData)) {
    return true
  }

  return false
}

export const getCentresFromStores = (
  selectedStores: string[],
  centres = [] as Centre[]
) => {
  const centresByStoreId = keyBy(centres, 'store')
  return selectedStores
    .map(storeId => centresByStoreId[storeId])
    .filter(Boolean)
}

export const formError = (formType: string) =>
  `You can't submit this ${formType} without a hero image`

export const returnURLWithHttps = (url: string) =>
  /^https?:\/\//i.test(url) ? url : `https://${url}`

export const isPostUnpublishable = (status: Status) =>
  [POST_STATUS.LIVE, POST_STATUS.SCHEDULED].includes(status)

export function lightOrDark(color?: string): ColourScheme {
  if (!color) {
    return ColourScheme.Light
  }

  try {
    const colorFromInput = Color(color)

    return colorFromInput.isDark() ? ColourScheme.Dark : ColourScheme.Light
  } catch (err) {
    // safety net to return light when error is invalid
    console.warn(err)
    return ColourScheme.Light
  }
}

// append one or more query parameters to url and return new url
export const addQueryParams = (url: string, querys: string[]) => {
  const querysString: string = querys.reduce(
    (preVal, currentQuery, currentIndex) => {
      if (currentIndex) {
        return `${preVal}&${currentQuery}`
      } else {
        return currentQuery
      }
    },
    ''
  )
  // eslint-disable-next-line no-magic-numbers
  if (url.split('?').length === 2) {
    return `${url}&${querysString}`
  }
  return `${url}?${querysString}`
}

export const isSquareImage = async (imageUrl: string) => {
  const { height, width } = await getImageDimensions(imageUrl)

  const hasValidDimensions =
    height >= MINIMUM_SQUARE_IMAGE_DIMENSIONS.height &&
    width >= MINIMUM_SQUARE_IMAGE_DIMENSIONS.width

  const isSquare = width <= height * WESTFIELD_DIRECT_SQUARE_IMAGE_RATIO

  return isSquare && hasValidDimensions
}

const getStoreTitles = (selectedStores: string[], stores: Store[]) => {
  const storeNamesById: Record<string, string> = stores.reduce(
    (acc, store) => ({
      [store.id]: store.uniqueTitle,
      ...acc,
    }),
    {}
  )
  const storeNames = selectedStores.map(storeId => storeNamesById[storeId])
  return ` - ${storeNames.join(' - ')}`
}

// generates a new social content title
export const autoCalcSocialTitle = (
  retailer: Retailer,
  data: SocialPostData,
  stores: Store[],
  hasAllStores: boolean
) => {
  const defaultPrefix = autoCalcRetailerPrefix(retailer.title)
  const currentDate = formatDate(new Date(), DateTimeFormat.TITLE)
  const country = countryCodes[retailer.country] || ''
  let socialTitle = `${defaultPrefix}Social Content`

  if (hasAllStores && data.allStores.includes('allStores')) {
    socialTitle += ` - ${country}`
  } else if (data.stores.length && data.stores.length <= 3) {
    socialTitle += getStoreTitles(data.stores, stores)
  }

  return `${socialTitle} - ${currentDate}`
}

export const formatLevel = (level?: string | null) =>
  level !== '' && centreLevelOptions.some(v => level === v.value)
    ? level
    : undefined

export const sanitizeId = (id: string) => {
  return id.trim().toLowerCase().replace(/\s+/g, '-')
}

dayjs.extend(localeData)
dayjs().localeData()

export const months = dayjs.months()

export const groupCardsIntoMonths = (cards: Array<CampaignCard>) => {
  const cardsWithMonths: { [key: string]: Array<CampaignCard> } = {}

  for (const card of cards) {
    const monthIndex = dayjs(card.startDate).get('month')
    const year = dayjs(card.startDate).get('year')
    const month = months[monthIndex]

    const date = `${month} ${year}`

    if (cardsWithMonths[date]) {
      cardsWithMonths[date].push(card)
    } else {
      cardsWithMonths[date] = [card]
    }
  }

  return cardsWithMonths
}

export const translateSelectedValueToLabels = (
  selectedValues: string[],
  options: SelectProps
) => {
  return selectedValues
    .map(
      selectedValue =>
        options.find(option => option.value === selectedValue)?.label
    )
    .filter(Boolean)
    .join(', ')
}

export const contactRoles = [
  {
    label: 'Store Contact',
    value: 'Store Contact',
  },
  {
    label: 'Maintenance Contact',
    value: 'Facilities Manager',
  },
  {
    label: 'Store Manager',
    value: 'Store Manager',
  },
  {
    label: 'Sales Turnover Contact',
    value: 'Sales Turnover Contact',
  },
  {
    label: 'After Hours/ Emergency Contact',
    value: 'Emergency Contact',
  },
  {
    label: 'Regional Store Manager',
    value: 'Regional Store Manager',
  },
  {
    label: 'Franchise Manager',
    value: 'Franchise Manager',
  },
  {
    label: 'Marketing Contact',
    value: 'Marketing Contact',
  },
  {
    label: 'Leasing / Property Contact',
    value: 'Leasing Contact',
  },
  {
    label: 'Accounts Payable Contact',
    value: 'Account Payable Contact',
  },
]

export const marketingHubRoles = [
  { label: 'Brand Level', value: 'BRAND_LEVEL' },
  { label: 'Store Level', value: 'STORE_LEVEL' },
]

export const scentreHubRoles = [
  { label: 'View Reports', value: 'REPORT_VIEWER' },
  { label: 'Manage my contacts', value: 'CONTACT_MANAGER' },
]
