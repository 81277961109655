import { makeStyles } from '@mui/styles'
import { determineMinWidth, SPACING } from '../../lib/theme'

type Props = { route: string; isPublicPage: boolean; isMobile: boolean }

export default makeStyles(theme => ({
  root: ({ route }: Props) => ({
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(SPACING.S)} 0`,
    maxHeight: '72px',
    justifyContent: 'center',
    minWidth: determineMinWidth(route, theme),
    width: '100%',
  }),
  toolbar: ({ isPublicPage, isMobile }: Props) => ({
    ...(isPublicPage && {
      justifyContent: isMobile ? 'center' : 'flex-end',
    }),
  }),
  rightContainer: {
    flexGrow: 1,
  },
  rightButton: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#2E0029',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'unset',
    },
    textDecoration: 'none',
  },
  leftButton: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#2E0029',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}))
