import { useAuth0, User } from '@auth0/auth0-react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AssessmentIcon from '@mui/icons-material/Assessment'
import SupportIcon from '@mui/icons-material/Contacts'
import DashboardIcon from '@mui/icons-material/Dashboard'
import StorefrontIcon from '@mui/icons-material/Storefront'
import SupervisedUserCircle from '@mui/icons-material/SupervisedUserCircle'
import React from 'react'
import { useRecoilValue } from 'recoil'
import useCurrentAppSection from 'hooks/useCurrentAppSection'
import useCurrentUserAccount from 'hooks/useCurrentUserAccount'
import { CampaignIcon } from 'lib/images'
import {
  AccountPermission,
  hasUserAccountPermission,
  hasUserRetailerPermission,
  Permission,
} from 'lib/permission'
import { currentRetailerDetailId } from 'lib/selectors/retailer'
import { CurrentUserAccount } from 'lib/types/account'
import { NavRouteItem } from 'lib/types/nav'
import { isWMHAdminUser } from 'lib/utils'

const includeIf = (condition: boolean, ...itemsToInclude: any[]) =>
  condition ? itemsToInclude : []

const marketingHubRoutes = (
  user: User | undefined,
  retailerId: string | null,
  retailerHasStores: boolean
) => [
  {
    urls: [{ url: '/marketing-hub', exact: true }],
    text: 'Home',
    icon: <DashboardIcon />,
    dataId: 'menu-home',
  },
  {
    urls: [{ url: '/marketing-hub/my-stores', exact: false }],
    text: 'Storefronts',
    icon: <StorefrontIcon />,
    dataId: 'menu-storefronts',
    hasDivider: true,
    childRoutes: [
      {
        urls: [{ url: '/marketing-hub/my-stores', exact: false }],
        text: 'My stores',
        dataId: 'submenu-my stores',
      },
      ...includeIf(
        hasUserRetailerPermission(Permission.UpdateBrand, user, retailerId),
        {
          urls: [{ url: '/marketing-hub/my-brand', exact: true }],
          text: 'My brand',
          dataId: 'submenu-my brand',
        }
      ),
    ],
  },
  {
    urls: [{ url: '/marketing-hub/posts', exact: true }],
    text: 'Campaigns',
    icon: <CampaignIcon />,
    dataId: 'menu-campaigns',
    hasDivider: true,
    childRoutes: [
      {
        urls: [
          { url: '/marketing-hub/posts', exact: true },
          { url: '/marketing-hub/campaigns/[type]', exact: false },
        ],
        text: 'All posts',
        dataId: 'submenu-all posts',
      },
      {
        urls: [{ url: '/marketing-hub/campaigns/calendar', exact: false }],
        text: 'Campaign opportunities',
        dataId: 'submenu-campaign calendar',
      },
      ...includeIf(
        retailerHasStores,
        {
          urls: [{ url: '/marketing-hub/campaigns/posts/new', exact: false }],
          text: 'Submit content',
          dataId: 'submenu-campaign submit',
        },
        {
          urls: [{ url: '/marketing-hub/campaigns/social/new', exact: false }],
          text: 'Upload social content',
          dataId: 'submenu-social submit',
        }
      ),
    ],
  },
]

const scentreHubRoutes = (
  user: User | undefined,
  retailerId: string | null
) => [
  ...includeIf(
    hasUserRetailerPermission(Permission.VisibilityReports, user, retailerId),
    {
      urls: [
        {
          url: '/scentre-hub/reporting/sales-performance-data',
          exact: true,
        },
      ],
      text: 'Reporting',
      icon: <AssessmentIcon />,
      dataId: 'menu-reporting',
      hasDivider: true,
      childRoutes: [
        {
          urls: [
            {
              url: '/scentre-hub/reporting/sales-performance-data',
              exact: true,
            },
          ],
          text: 'Sales performance data',
          dataId: 'submenu-sales-performance-data',
        },
      ],
    }
  ),
  {
    urls: [{ url: '/scentre-hub/support/contact-directory', exact: true }],
    text: 'Support',
    icon: <SupportIcon />,
    dataId: 'menu-support',
    hasDivider: true,
    childRoutes: [
      {
        urls: [{ url: '/scentre-hub/support/contact-directory', exact: true }],
        text: 'Contact directory',
        dataId: 'submenu-contact-directory',
      },
    ],
  },
  ...includeIf(
    hasUserRetailerPermission(Permission.ReadContacts, user, retailerId),
    {
      urls: [{ url: '/scentre-hub/my-account/my-contacts', exact: true }],
      text: 'My Account',
      icon: <AccountBoxIcon />,
      dataId: 'menu-my-account',
      hasDivider: true,
      childRoutes: [
        {
          urls: [{ url: '/scentre-hub/my-account/my-contacts', exact: true }],
          text: 'My contacts',
          dataId: 'submenu-my-contacts',
        },
        ...includeIf(
          hasUserRetailerPermission(
            Permission.CreateContacts,
            user,
            retailerId
          ),
          {
            urls: [
              {
                url: '/scentre-hub/my-account/my-contacts/new',
                exact: true,
              },
            ],
            text: 'Create a contact',
            dataId: 'submenu-create-a-contact',
          }
        ),
      ],
    }
  ),
]

const betaRoutes = (
  user: User | undefined,
  currentUserAccount?: CurrentUserAccount | null
) => [
  ...includeIf(
    hasUserAccountPermission(AccountPermission.ReadReports, currentUserAccount),
    {
      urls: [
        {
          url: '/beta/reporting/sales-performance-data',
          exact: true,
        },
      ],
      text: 'Reporting',
      icon: <AssessmentIcon />,
      dataId: 'menu-reporting',
      hasDivider: true,
      childRoutes: [
        {
          urls: [
            {
              url: '/beta/reporting/sales-performance-data',
              exact: true,
            },
          ],
          text: 'Sales performance data',
          dataId: 'submenu-sales-performance-data',
        },
      ],
    }
  ),
  {
    urls: [{ url: '/beta/support/contact-directory', exact: true }],
    text: 'Support',
    icon: <SupportIcon />,
    dataId: 'menu-support',
    hasDivider: true,
    childRoutes: [
      {
        urls: [{ url: '/beta/support/contact-directory', exact: true }],
        text: 'Contact directory',
        dataId: 'submenu-contact-directory',
      },
    ],
  },
  ...includeIf(
    hasUserAccountPermission(
      AccountPermission.ReadContacts,
      currentUserAccount
    ),
    {
      urls: [{ url: '/beta/my-account/my-contacts', exact: true }],
      text: 'My Account',
      icon: <AccountBoxIcon />,
      dataId: 'menu-my-account',
      hasDivider: true,
      childRoutes: [
        {
          urls: [{ url: '/beta/my-account/my-contacts', exact: true }],
          text: 'My contacts',
          dataId: 'submenu-my-contacts',
        },
        ...includeIf(
          hasUserAccountPermission(
            AccountPermission.WriteContacts,
            currentUserAccount
          ),
          {
            urls: [
              {
                url: '/beta/my-account/my-contacts/new',
                exact: true,
              },
            ],
            text: 'Create a contact',
            dataId: 'submenu-create-a-contact',
          }
        ),
      ],
    }
  ),
  ...includeIf(isWMHAdminUser(user), {
    urls: [{ url: '/beta', exact: true }],
    text: 'Admin',
    icon: <SupervisedUserCircle />,
    dataId: 'menu-beta',
    childRoutes: [
      {
        urls: [{ url: '/beta/admin/user/create', exact: true }],
        text: 'Create a user',
        dataId: 'submenu-users',
      },
    ],
  }),
]

const getAdminRoutes = () => [
  {
    urls: [{ url: '/admin', exact: true }],
    text: 'Admin',
    icon: <SupervisedUserCircle />,
    dataId: 'menu-admin',
    childRoutes: [
      {
        urls: [
          { url: '/admin', exact: true },
          { url: '/admin/user', exact: false },
        ],
        text: 'Users',
        dataId: 'submenu-users',
      },
      {
        urls: [{ url: '/admin/retailers', exact: false }],
        text: 'Retailers',
        dataId: 'submenu-retailers',
      },
    ],
  },
]

const useRoutes = (retailerHasStores: boolean): NavRouteItem[] => {
  const currentAppSection = useCurrentAppSection()
  const currentUserAccount = useCurrentUserAccount()
  const { user } = useAuth0()
  const retailerId = useRecoilValue(currentRetailerDetailId)

  return [
    ...includeIf(
      currentAppSection === 'marketing-hub',
      ...marketingHubRoutes(user, retailerId, retailerHasStores),
      ...includeIf(isWMHAdminUser(user), ...getAdminRoutes())
    ),
    ...includeIf(
      currentAppSection === 'scentre-hub',
      ...scentreHubRoutes(user, retailerId)
    ),
    ...includeIf(
      currentAppSection === 'beta',
      ...betaRoutes(user, currentUserAccount)
    ),
  ]
}

export default useRoutes
