import sxStyles from 'lib/sxStyles'
import { Option } from './types'

const styles = sxStyles({
  autocomplete: (loading?: boolean) => ({
    width: 300,
    opacity: loading ? '0.3' : '1',
    '.MuiAutocomplete-listbox': {
      overflow: 'visible',
    },
  }),
  option: (option: Option) => ({
    '&.MuiAutocomplete-option': {
      paddingLeft: 2 + option.depth * 2,
      height: '36px',
      alignItems: 'center',
    },
    display: 'flex',
  }),
  optionInner: (option: Option) => ({
    display: 'flex',
    alignItems: 'baseline',
    whiteSpace: 'pre-wrap',
    position: 'relative',
    color: 'rgb(0,0,0,0.95)',
    ...(option.isLeaf &&
      option.hasOnlySiblingsOfEqualHeight && {
        paddingLeft: '12px',
        border: 'none',
        color: 'rgb(0,0,0,0.85)',
        '&:before': {
          content: '""',
          height: '36px',
          width: '2px',
          left: '0',
          top: '-6px',
          position: 'absolute',
          backgroundColor: 'rgba(0,0,0,0.03)',
        },
      }),
  }),
  highlightedPart: (asBold: boolean) => ({
    verticalAlign: 'bottom',
    fontWeight: asBold ? 700 : 400,
  }),
})

export default styles
