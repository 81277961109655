import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(theme => ({
  root: {
    width: '280px',
    minWidth: '280px',
    height: '100%',
    background: theme.palette.grey[50],
  },
  parentListItem: {
    marginTop: theme.spacing(SPACING.XXS),

    '&.active': {
      background: '#50d2dc',
      color: '#2e0029',
      '& .MuiSvgIcon-root': {
        color: '#2e0029 !important',
      },
    },
  },
  parentListItemButton: {
    paddingLeft: theme.spacing(SPACING.M),
  },
  childList: {
    padding: 0,
  },
  childListItemButton: {
    paddingLeft: theme.spacing(SPACING.S),
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
  },
  listItemIcon: {
    minWidth: theme.spacing(SPACING.L),
    '& > .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
      color: '#0000008a',
    },
  },
  childActive: {
    '& > .MuiTypography-body2': {
      fontWeight: '700',
      color: theme.palette.primary.main,
    },
  },
  navDivider: {
    opacity: 0.5,
    marginTop: theme.spacing(SPACING.S),
  },
  retailerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: `${theme.spacing(SPACING.S)} ${theme.spacing(
      SPACING.M
    )} ${theme.spacing(SPACING.XXS)}`,
  },
}))
