import { atom } from 'recoil'
import { LOCAL_STORAGE_KEYS } from 'lib/config'
import { storageEffect } from 'lib/recoilUtil'
import { CurrentUserAccount } from 'lib/types/account'

export const currentUserAccountStorageKey =
  LOCAL_STORAGE_KEYS.CURRENT_USER_ACCOUNT

export const currentUserAccountAtom = atom<CurrentUserAccount | null>({
  key: 'currentUserAccount',
  default: null,
  effects_UNSTABLE: [storageEffect(currentUserAccountStorageKey, 'session')],
})
