import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'
import useStyles from './styles'

const PageContent = ({ children }: { children: ReactNode }) => {
  const classes = useStyles()

  return <Box className={classes.pageContent}>{children}</Box>
}

export default PageContent
