import { User } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import useSWRImmutable from 'swr/immutable'
import { fetcher } from 'lib/api'
import { hasBootstrappedLoginAtom, profile } from 'lib/atoms/user'
import { API_URL } from 'lib/config'
import { UserProfile } from 'lib/types/user'

const useInitUserProfile = (user?: User) => {
  const [userProfile, setUserProfile] = useRecoilState(profile)
  const hasBootstrappedLogin = useRecoilValue(hasBootstrappedLoginAtom)

  const {
    data: profileResponse,
    isValidating,
    isLoading,
    error,
  } = useSWRImmutable(
    (userProfile && userProfile.userId === user?.sub) || !hasBootstrappedLogin
      ? null
      : [`${API_URL}/me`, user?.sub],
    ([url]) => fetcher<Omit<UserProfile, 'userId'>>(url)
  )

  useEffect(() => {
    if (profileResponse && !error && !isValidating && !isLoading && user?.sub) {
      setUserProfile({
        userId: user.sub,
        ...profileResponse.data,
      })
    }
  }, [profileResponse, error, isValidating, isLoading])

  return hasBootstrappedLogin && user ? userProfile : null
}

export default useInitUserProfile
