import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { GridProps } from '@mui/material/Grid'
import { MenuItemProps } from '@mui/material/MenuItem'
import { sanitizeTrackId } from 'lib/gtm'
import useStyles from './trackBoxStyles'

type TrackBoxGroupProps = BoxProps &
  GridProps &
  MenuItemProps & {
    id: string
    // Allows usage of TrackBoxGroup without inflencing/breaking css styles of child components
    transparent?: boolean
    children: any
  }

// This is a GTM helper Group Box (or Grid) component that wraps children, containing TrackBox components, with data-group-id
const TrackBoxGroup = ({
  id,
  transparent,
  children,
  ...rest
}: TrackBoxGroupProps) => {
  const classes = useStyles({ transparent })

  return (
    <Box
      data-track-group={sanitizeTrackId(id)}
      className={classes.root}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default TrackBoxGroup
