import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

type StyleProps = {
  hasImage: boolean
}

export default makeStyles(theme => ({
  root: ({ hasImage }: StyleProps) => ({
    '& .MuiDialog-container > .MuiDialog-paper': {
      width: hasImage ? '600px' : '796px',
      minHeight: hasImage ? '273px' : '184px',
    },
  }),
  content: () => ({
    display: 'flex',
    height: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: theme.spacing(SPACING.S),
    '& .MuiDialogContentText-root': {
      fontSize: theme.spacing(SPACING.XS),
      color: theme.palette.primary.main,
      lineHeight: theme.spacing(SPACING.S),
    },
  }),
  paragraphs: () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  }),
  imageContainer: () => ({
    width: '208px',
    height: '150px',
  }),
}))
