import React, { useCallback, useEffect, useState } from 'react'
import HierarchicalAutocomplete, {
  HierarchicalOption,
} from 'components/HierarchicalAutocomplete/HierarchicalAutocomplete'
import { HierarchicalAutocompleteValue } from 'components/HierarchicalAutocomplete/types'
import useCurrentUserAccount, {
  useSetCurrentUserAccount,
} from 'hooks/useCurrentUserAccount'
import useUserProfile, {
  findAccountByAccountNumber,
} from 'hooks/useUserProfile'
import { UserProfileAccountTreeItem } from 'lib/types/user'

const mapToHierarchicalOption = (
  account: UserProfileAccountTreeItem
): HierarchicalOption => ({
  id: account.accountNumber,
  label: account.accountName,
  disabled: !account.hasAccess,
  children: account.children.map(mapToHierarchicalOption),
})

const AccountSwitcher = () => {
  const userProfile = useUserProfile()
  const currentUserAccount = useCurrentUserAccount()
  const setCurrentUserAccount = useSetCurrentUserAccount()

  const accounts = userProfile?.accounts || []
  const [{ loadingCurrentAccount, value }, setSwitcherState] = useState({
    loadingCurrentAccount: true,
    value: null as HierarchicalAutocompleteValue | null,
  })
  const [hierarchicalOptions, setHierarchicalOptions] = useState(
    [] as HierarchicalOption[]
  )

  // Configure the initial value or mismatching value for the select
  useEffect(() => {
    if (
      accounts.length &&
      (!value || value.id !== currentUserAccount?.accountNumber)
    ) {
      const account = findAccountByAccountNumber(
        accounts,
        currentUserAccount?.accountNumber
      )
      if (account) {
        setSwitcherState({
          value: mapToHierarchicalOption(account),
          loadingCurrentAccount: false,
        })
      }
    }
  }, [accounts, currentUserAccount])

  // Configure the options for the select
  useEffect(() => {
    if (accounts.length) {
      setHierarchicalOptions(accounts.map(mapToHierarchicalOption))
    }
  }, [accounts, setHierarchicalOptions])

  const onChange = useCallback(
    async (updatedValue: HierarchicalAutocompleteValue | null) => {
      if (updatedValue && setCurrentUserAccount) {
        setSwitcherState({
          value: updatedValue,
          loadingCurrentAccount: true,
        })
        await setCurrentUserAccount(updatedValue.id)
        setSwitcherState(prevState => ({
          ...prevState,
          loadingCurrentAccount: false,
        }))
      }
    },
    [setCurrentUserAccount]
  )

  return (
    <HierarchicalAutocomplete
      options={hierarchicalOptions}
      loading={loadingCurrentAccount || !userProfile}
      value={value}
      onChange={onChange}
      placeholder="Account"
    />
  )
}

export default AccountSwitcher
