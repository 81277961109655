import React, { MutableRefObject } from 'react'
import { Crop } from 'react-image-crop'

export const getCroppedImg = (
  fileUrl: string,
  image: MutableRefObject<HTMLImageElement | null>,
  newCrop: Crop
): Promise<string> => {
  const canvas = document.createElement('canvas')
  const scaleX = image.current
    ? image.current.naturalWidth / image.current.width
    : 0
  const scaleY = image.current
    ? image.current.naturalHeight / image.current.height
    : 0
  canvas.width = Math.ceil((newCrop?.width || 0) * scaleX)
  canvas.height = Math.ceil((newCrop?.height || 0) * scaleY)
  const ctx = canvas.getContext('2d')

  if (image.current) {
    ctx?.drawImage(
      image.current,
      (newCrop?.x || 0) * scaleX,
      (newCrop?.y || 0) * scaleY,
      (newCrop?.width || 0) * scaleX,
      (newCrop?.height || 0) * scaleY,
      0,
      0,
      (newCrop?.width || 0) * scaleX,
      (newCrop?.height || 0) * scaleY
    )
  }

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      if (!blob) {
        console.error('Canvas is empty')
        return
      }

      window.URL.revokeObjectURL(fileUrl)
      resolve(window.URL.createObjectURL(blob))
    }, 'image/png')
  })
}

export const CampaignIcon = () => (
  <svg
    className="MuiSvgIcon-root"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <path d="M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm11.5 3c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z" />
  </svg>
)
