import { SxProps } from '@mui/system/styleFunctionSx'

/**
 * An opinionated type-safe style helper for use with configuring multiple SxProps objects.
 *
 * @param styles An object consisting of an arbitrary name (for purposes of providing context to the assigned SxProps
 *               object) as the key, and either an SxProps object or an SxProps object generator function as the value.
 *               The generator is just a simple function which can be called on render to provide dynamic styles.
 *
 * @example
 * // Declaration
 * const styles = sxStyles({
 *   styleOne: {
 *     paddingLeft: 2,
 *     marginTop: 5,
 *   },
 *   styleTwoGenerator: (arg1, arg2) => ({
 *     paddingLeft: arg1 === true ? '5px' : 0,
 *     marginTop: arg2,
 *   })
 * })
 *
 * // Usage
 * <Box sx={styles.styleOne}>Test Style One</Box>
 * <Box sx={styles.styleTwo(true, 4)}>Test Style Two</Box>
 */
const sxStyles = <
  StylesObj extends Record<Key, Value | ((...any: any[]) => Value)>,
  Key extends string,
  Value extends SxProps
>(
  styles: StylesObj
) => styles

export default sxStyles
