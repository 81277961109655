import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import cx from 'classnames'
import { useRouter } from 'next/router'
import React, { ComponentProps } from 'react'
import { useRecoilValue } from 'recoil'
import AccountSwitcher from 'components/AccountSwitcher/AccountSwitcher'
import useRoutes from 'components/NavDrawer/useRoutes'
import RetailerSwitcher from 'components/RetailerSwitcher/RetailerSwitcher'
import TrackBox from 'components/Track/TrackBox'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'
import useCurrentAppSection from 'hooks/useCurrentAppSection'
import usePublicPage from 'hooks/usePublicPage'
import { retailerStores } from 'lib/atoms/retailer'
import { hasAcceptedTerms } from 'lib/atoms/user'
import { NavRoute, NavRouteItem } from 'lib/types/nav'
import useStyles from './styles'

const ListItemNoPadding = (props: ComponentProps<typeof ListItem>) => (
  <ListItem {...props} disablePadding />
)

const NavDrawer = () => {
  const theme = useTheme()
  const { pathname, push } = useRouter()
  const acceptedTerms = useRecoilValue(hasAcceptedTerms)
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile })
  const retailerStoresDetails = useRecoilValue(retailerStores)
  const retailerHasStores =
    retailerStoresDetails.storeData?.data?.items.length > 0
  const currentAppSection = useCurrentAppSection()
  const isPublicPage = usePublicPage()
  const navigationRoutes = useRoutes(retailerHasStores)

  const isUrlMatch = ({ url, exact }: NavRoute) => {
    return exact ? pathname === url : pathname.startsWith(url)
  }

  const isActiveChildItem = (navItem: NavRouteItem) => {
    return navItem.urls.some(urlData => isUrlMatch(urlData))
  }

  const isActiveParentItem = (navItem: NavRouteItem) => {
    if (navItem.childRoutes) {
      return navItem.childRoutes.some(child => isActiveChildItem(child))
    }

    return isUrlMatch(navItem.urls[0])
  }

  if (!acceptedTerms || isPublicPage) {
    return null
  }

  const getSwitcher = () => {
    if (currentAppSection === 'beta') {
      return <AccountSwitcher />
    } else if (['scentre-hub', 'marketing-hub'].includes(currentAppSection)) {
      return (
        <RetailerSwitcher
          name="retailer-select"
          data-test-id="retailer-select"
        />
      )
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.retailerContainer}>{getSwitcher()}</Box>
      <TrackBoxGroup id="sidebar-nav">
        <List>
          {navigationRoutes.map(route => (
            <React.Fragment key={route.urls[0].url}>
              <TrackBox
                id={route.text}
                component={ListItemNoPadding}
                onClick={() => {
                  push(route.urls[0].url)
                }}
                className={cx(
                  classes.parentListItem,
                  isActiveParentItem(route) ? 'active' : ''
                )}
                data-test-id={route.dataId}
              >
                <ListItemButton className={classes.parentListItemButton}>
                  <ListItemIcon
                    className={classes.listItemIcon}
                    data-test-id={`${route.dataId}-icon`}
                  >
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText primary={route.text} />
                </ListItemButton>
              </TrackBox>
              {route.childRoutes && (
                <Box marginLeft={6}>
                  <List className={classes.childList}>
                    {route.childRoutes.map(childRoute => (
                      <TrackBox
                        id={childRoute.text}
                        component={ListItemNoPadding}
                        key={childRoute.urls[0].url}
                        onClick={() => {
                          push(childRoute.urls[0].url)
                        }}
                        data-test-id={childRoute.dataId}
                      >
                        <ListItemButton className={classes.childListItemButton}>
                          <ListItemText
                            className={
                              isActiveChildItem(childRoute)
                                ? classes.childActive
                                : undefined
                            }
                            secondary={childRoute.text}
                          />
                        </ListItemButton>
                      </TrackBox>
                    ))}
                  </List>
                </Box>
              )}
              {route.hasDivider && <Divider className={classes.navDivider} />}
            </React.Fragment>
          ))}
        </List>
      </TrackBoxGroup>
    </Box>
  )
}

export default NavDrawer
