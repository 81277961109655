import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
  PAGE_FLAGS,
  DYNAMIC_PAGE_URL_FULL_WIDTH,
  SPACING,
  determineMinWidth,
} from '../../lib/theme'

const SM = (theme: Theme) => theme.spacing(SPACING.S)
const XS = (theme: Theme) => theme.spacing(SPACING.XS)

type Props = { route: string }

const useStyles = makeStyles(theme => ({
  root: ({ route }: Props) => ({
    display: 'flex',
    flex: 1,
    fontFamily: 'Proxima Nova',
    backgroundColor: theme.palette.common.white,
    flexDirection: 'column',
    minWidth: determineMinWidth(route, theme),
    width: '100%',
  }),
  containerDiv: ({ route }: Props) => ({
    minWidth: determineMinWidth(route, theme),
    width:
      PAGE_FLAGS.FULL_WIDTH(route) || DYNAMIC_PAGE_URL_FULL_WIDTH(route)
        ? '100%'
        : theme.breakpoints?.values?.xs,
    padding:
      PAGE_FLAGS.FULL_WIDTH(route) || DYNAMIC_PAGE_URL_FULL_WIDTH(route)
        ? `${XS(theme)} 0 0 0`
        : `${XS(theme)} ${SM(theme)} ${SM(theme)} ${SM(theme)}`,
    maxWidth: theme.breakpoints?.values?.lg,
    margin: '0 auto',
  }),
  rootNav: ({ route }: Props) => ({
    display: 'flex',
    backgroundColor: '#f7f7f7',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: determineMinWidth(route, theme),
    width: '100%',
  }),
  fullWidth: ({ route }: Props) => ({
    minWidth: determineMinWidth(route, theme),
    width: '100%',
  }),
}))

export default useStyles
