import React from 'react'
import MuiDialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import { TransitionProps } from '@mui/material/transitions'
import { DialogProps } from 'lib/types/dialog'
import useStyles from './styles'
import Image from 'next/image'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Dialog = ({
  isFeedbackOnly,
  isOpen,
  title,
  content,
  imageSrc,
  customConfirmLabel,
  customCancelLabel,
  onConfirm,
  onCancel,
}: DialogProps) => {
  const classes = useStyles({ hasImage: !!imageSrc })

  const determineConfirmLabel = () => {
    if (customConfirmLabel) {
      return customConfirmLabel
    }

    return isFeedbackOnly ? 'OK' : 'YES'
  }

  return (
    <MuiDialog
      className={classes.root}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <DialogTitle data-test-id="dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.content} data-test-id="dialog-content">
        {Array.isArray(content) ? (
          <Box className={classes.paragraphs}>
            {content.map((paragraph, idx) => (
              <Typography key={idx}>{paragraph}</Typography>
            ))}
          </Box>
        ) : (
          <Box>{content}</Box>
        )}
        {imageSrc && (
          <Box className={classes.imageContainer}>
            <Image
              data-test-id="dialog-image"
              src={`/${imageSrc}`}
              alt="dialog-image"
              width={208}
              height={150}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!isFeedbackOnly && (
          <Button
            data-test-id="dialog-cancel"
            variant="text"
            color="secondary"
            onClick={onCancel}
          >
            {customCancelLabel || 'NO'}
          </Button>
        )}
        <Button
          data-test-id="dialog-confirm"
          variant="text"
          color="secondary"
          onClick={onConfirm}
        >
          {determineConfirmLabel()}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
