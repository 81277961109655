import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(theme => ({
  select: {
    width: '100%',
    background: theme.palette.common.white,
    '& > .MuiSelect-select': {
      width: '190px',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(SPACING.XXS),
      padding: theme.spacing(SPACING.XXS),
      background: theme.palette.common.white,
      '& > .MuiBox-root': {
        width: '148px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& .MuiAvatar-root': {
        width: '30px',
        height: '30px',
      },
    },
  },
  option: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(SPACING.XXS),
    '& .MuiAvatar-root': {
      width: '30px',
      height: '30px',
    },
  },
}))
